import { useEffect } from 'react'
import Swiper, { Mousewheel, Pagination, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Mousewheel, Pagination, Autoplay])

export default function Home() {
  useEffect(() => {
    /* eslint-disable no-new */
    new Swiper('.fullPage', {
      direction: 'vertical',
      resistanceRatio: 0,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      mousewheel: true,
      autoplay: true
    })
  }, [])

  return (
    <>
      <div className='swiper-container fullPage'>
        <div className='swiper-wrapper'>

          <div className='swiper-slide'>
            <div className='h_c'>
              <img
                src='./image/2.png'
                alt=''
              />
            </div>
            <div className='h_c'>
              <div className='f_r'>
                <div className='word'>剧本星球</div>
              </div>
              <div className='h_d'>
                <img
                  src='./image/Frame1801.png'
                  alt=''
                />
              </div>
            </div>
          </div>

          <div className='swiper-slide'>
            <div className='h_c'>
              <div className='f_l'>
                <div className='word'>剧本展示</div>
                <div className='word'>海量剧本库，一键添加，种类多样剧本介绍丰富，分类检索高效</div>
              </div>
            </div>
            <div className='h_c ta_r'>
              <img
                src='./image/3.png'
                alt=''
              />
            </div>
          </div>

          <div className='swiper-slide'>
            <div className='h_c'>
              <img
                src='./image/4.png'
                alt=''
              />
            </div>
            <div className='h_c'>
              <div className='f_r'>
                <div className='word'>组局预约</div>
                <div className='word'>一键分享，预收款项，有效防鸽<br></br>及时通知组局信息、分享好友加<br></br>入组局</div>
              </div>
            </div>
          </div>

          <div className='swiper-slide'>
            <div className='h_c'>
              <div className='f_r'>
                <div className='word'>智能推送</div>
                <div className='word'>提高效率杀手锏，促进组局省时间<br></br>订阅式组局推送，根据玩家爱好<br></br>智能匹配组局，提高组局效率</div>
              </div>
            </div>
            <div className='h_c ta_r'>
              <img
                src='./image/5.png'
                alt=''
              />
            </div>
          </div>

          <div className='swiper-slide'>
            <div className='h_c'>
              <img
                src='./image/6.png'
                alt=''
              />
            </div>
            <div className='h_c'>
              <div className='f_r'>
                <div className='word'>日程管理</div>
                <div className='word'>安排有规律，排班更有序<br></br>规划场次和房间，有效避免遗忘和冲突</div>
              </div>
            </div>
          </div>

          <div className='swiper-slide'>
            <div className='h_c'>
              <div className='f_r'>
                <div className='word'>账本功能</div>
                <div className='word'>账目不再记错，店铺利润全盘掌握<br></br>三秒完成一笔记账，每日业绩自动入账<br></br>类目设置定义灵活</div>
              </div>
            </div>
            <div className='h_c'>
              <img
                src='./image/7.png'
                alt=''
              />
            </div>
          </div>

          <div className='swiper-slide'>
            <div className='h_c'>
              <img
                src='./image/8.png'
                alt=''
              />
            </div>
            <div className='h_c'>
              <div className='f_r'>
                <div className='word'>数据统计</div>
                <div className='word'>数据井井有条，信息快速知晓<br></br>店铺业绩一览无余，剧本开本场次一目了然<br></br>统计DM工资不再麻烦</div>
              </div>
            </div>
          </div>

          <div className='swiper-slide'>
            <div className='h_c'>
              <div className='f_r'>
                <div className='word'>随身管理</div>
                <div className='word'>上手仅需一分钟，管理店铺变轻松<br></br>手机搞定全部操作，无需电脑拒绝繁琐<br></br>随时随地管理门店</div>
              </div>
            </div>
            <div className='h_c'>
              <img
                src='./image/9.png'
                alt=''
              />
            </div>
          </div>

          <div className='swiper-slide'>
            <div className='h_c'>
              <img
                src='./image/10.png'
                alt=''
              />
            </div>
            <div className='h_c'>
              <div className='f_r'>
                <div className='word'>资金安全</div>
                <div className='word'>资金迅速到账，安全且有保障<br></br>微信官方支付系统，资金直接进入微信商户<br></br>号，平台不经手任何资金</div>
              </div>
            </div>
          </div>

          <div className='swiper-slide'>
            <div className='h_c'>
              <div className='f_r'>
                <div className='word'>客流保护</div>
                <div className='word'>保护私域流量，玩家信息不外泄<br></br>独立门店小程序，私域流量不互通<br></br>保护客流不外泄</div>
              </div>
            </div>
            <div className='h_c'>
              <img
                src='./image/11.png'
                alt=''
              />
            </div>
          </div>
        </div>

        <div className='swiper-pagination swiper-pagination-bullets'></div>
        <style scoped>
          {`
          img{
            height:557px;
          }
            .f_l {
              float: left;
            }

            .ta_r {
              text-align: right;
            }

            .h_c {
              padding-top: 90px;
              flex: 1.5;
              display: inline-block;
              max-width:500px;
              margin: 0px 32px;
            }
            .h_d {
              width: 678px;
              height: 61px;
              left: 0px;
              top: 115px;
              font-family: Alibaba PuHuiTi;
              font-style: normal;
              font-weight: 500;
              font-size: 36px;
              line-height: 49px;
            }

            .h_d img {
              width: 678px;
              height: 61px;
            }
            .h_c .word {
              margin: 12px 0 0 0;
              color: white;
              font-family: 'Alibaba PuHuiTi';
              min-width: normal;
              font-weight: normal;
              font-size: 28px;
              line-height: 38px;
              letter-spacing: 0.1px;
              opacity: 0.8;
            }

            .h_c .word:first-child {
              font-family: 'Roboto-Bold', sans-serif !important;
              font-size: 60px;
              line-height: 94px;
              color: #ffffff;
              opacity: 1;
            }

            .fullPage {
              min-width: 980px;
              height: 100vh;
              // background: rgba(0, 0, 0, 0.8);
            }
            .shuo_di .swiper-slide .h_c .word:first-child {
              font-size: 60px;
              line-height: 94px;
              color: #9DE7F8;
            }
          `}
        </style>
        <style>
          {`
            .swiper-slide {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 49px;
              font-style: normal;
              font-weight: bold;
              letter-spacing: 0.1px;
            }

            .swiper-pagination-bullets {
              right: 0 !important;
              left: 70px;
            }

            .swiper-pagination .swiper-pagination-bullet {
              margin: 33px 0 !important;
              width: 10px;
              height: 3px;
              background-color: #ffffff;
              border-radius: 0;
              border: none;
            }

            .swiper-pagination .swiper-pagination-bullet-active {
              width: 20px;
              height: 6px;
              background-color: #ffffff;
            }
            .swiper-slide {
              transform: translate3d(0, 0, 0);
              overflow: hidden;
            }
            .swiper-container{
              z-index:auto !important;
            }
          `}
        </style>
      </div>
    </>
  )
}
