import { useEffect } from 'react'
import Swiper, { Mousewheel, Pagination, Autoplay } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Mousewheel, Pagination, Autoplay])

export default function Feature() {
  useEffect(() => {
    /* eslint-disable no-new */
    new Swiper('.fullPage', {
      direction: 'vertical',
      resistanceRatio: 0,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      mousewheel: true,
      autoplay: true
    })
  }, [])

  return (
    <>
      <div className='swiper-container fullPage'>
        <div className='swiper-wrapper'>
          <div className='swiper-slide'>
            <div className='h_c'>
              <div className='f_r'>
                <div className='word'>aaa</div>
                <div className='word'>meet millions of pretty girls</div>
              </div>
            </div>
            <div className='h_c'>
              <img
                src='./image/TextAndVoiceChat.png'
                alt=''
              />
            </div>
          </div>
          <div className='swiper-slide'>
            <div className='h_c ta_r'>
              <img
                src='./image/PerfectSync.png'
                alt=''
              />
            </div>
            <div className='h_c'>
              <div className='f_l'>
                <div className='word'>Video Chat</div>
                <div className='word'>private, whenever, wherever</div>
              </div>
            </div>
          </div>
          <div className='swiper-slide'>
            <div className='h_c'>
              <div className='f_r'>
                <div className='word'>Smart Match</div>
                <div className='word'>find people with your type</div>
              </div>
            </div>
            <div className='h_c'>
              <img
                src='./image/AggregateMultipleMedia.png'
                alt=''
              />
            </div>
          </div>
          <div className='swiper-slide'>
            <div className='h_c ta_r'>
              <img
                src='./image/FreetoCreatMyRoom.png'
                alt=''
              />
            </div>
            <div className='h_c'>
              <div className='f_l'>
                <div className='word'>Real Connections</div>
                <div className='word'>chat with real girls, not robots</div>
              </div>
            </div>
          </div>
          <div className='swiper-slide'>
            <div className='h_c'>
              <div className='f_r'>
                <div className='word'>Post Moments</div>
                <div className='word'>share life with new people</div>
              </div>
            </div>
            <div className='h_c'>
              <img
                src='./image/MeetNewFriends.png'
                alt=''
              />
            </div>
          </div>
        </div>
        <div className='swiper-pagination swiper-pagination-bullets'></div>
        <style scoped>
          {`
          img{
            height:557px;
          }
            .f_l {
              float: left;
            }

            .f_r {
              float: right;
            }

            .ta_r {
              text-align: right;
            }

            .h_c {
              padding-top: 90px;
              flex: 1.5;
              display: inline-block;
              max-width:500px;
              margin: 0px 32px;
            }

            .h_c .word {
              margin: 12px 0 0 0;
              color: white;
              font-family: 'Roboto-Regular', sans-serif !important;
              min-width: 300px;
              font-weight: 600;
              font-size: 28px;
              line-height: 47px;
              letter-spacing: 0.1px;
              opacity: 0.8;
            }

            .h_c .word:first-child {
              font-family: 'Roboto-Bold', sans-serif !important;
              font-size: 60px;
              line-height: 94px;
              color: #9DE7F8;
              opacity: 1;
            }

            .fullPage {
              min-width: 980px;
              height: 100vh;
              // background: rgba(0, 0, 0, 0.8);
            }
            .shuo_di .swiper-slide .h_c .word:first-child {
              font-size: 60px;
              line-height: 94px;
              color: #9DE7F8;
            }
          `}
        </style>
        <style>
          {`
            .swiper-slide {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 49px;
              font-style: normal;
              font-weight: bold;
              letter-spacing: 0.1px;
            }

            .swiper-pagination-bullets {
              right: 0 !important;
              left: 70px;
            }

            .swiper-pagination .swiper-pagination-bullet {
              margin: 33px 0 !important;
              width: 10px;
              height: 3px;
              background-color: #ffffff;
              border-radius: 0;
              border: none;
            }

            .swiper-pagination .swiper-pagination-bullet-active {
              width: 20px;
              height: 6px;
              background-color: #ffffff;
            }
            .swiper-slide {
              transform: translate3d(0, 0, 0);
              overflow: hidden;
            }
            .swiper-container{
              z-index:auto !important;
            }
          `}
        </style>
      </div>
    </>
  )
}
