import Layout from './components/Layout';
// import MLayout from './components/mobile/Layout';
import './styles/globals.css'

function App() {
  const userAgent = window.navigator.userAgent
  const mobileRegex = /android|iphone|ipad|ipod|mobile/i
  return (
    <div>
      {/* {!mobileRegex.test(userAgent) ? (
        <div>
          <Layout></Layout>
        </div>
      ) : (
        <div>
          <MLayout></MLayout>
        </div>
      )} */}
      <Layout></Layout>
    </div>
  );
}

export default App;
