import styles from '../styles/About.module.scss'

export default function About() {

  return (
    <>
    <div className={styles.about}>
      <h3>About Us</h3>
      <p>HOYA is a social platform where boys can meet beautiful girls and chat and date with girls online.</p>
      <p>We make it easy and sincere to meet and start a conversation. Every new match begins by someone taping, visiting, liking or commenting on a specific part of your profile. You can text chat, voice chat or video chat with strangers you like and all your desire can come true here.</p>
      <h3 style={{ marginTop: "52px" }}>Contact Us</h3>
      <p>Email：jw007398@gmail.com</p>
    </div>
    </>
  )
}
