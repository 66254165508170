import styles from "../styles/Header.module.scss";

function Header() {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.left_content}>
          <div className={styles.block}>
            <img className={styles.logo} src="./image/icon.png" alt=""/>
          </div>
        </div>
        <div className={styles.right_content}>
          <div className={styles.shouye}>
            首页
          </div>
          <div className={styles.gongneng}>
            功能
          </div>
          <img className={styles.p_img} src="./image/Frame.png" alt="" />
          {/* <NavLink exact to="/About" activeStyle={{ color: "#fff" }}>
            About Us
          </NavLink> */}
        </div>
        {/* <div className={styles.right_content}> */}
          
          {/* <div className={styles.qrcodeWrap}>
            <a
              href="https://apps.apple.com/us/app/hoya-meet-chat-dating/id1549519975"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={styles.p_img} src="./image/ios.png" alt="" />
            </a> */}
            {/* iOS二维码 */}
          {/* </div> */}
          {/* <div className={styles.qrcodeWrap}>
            <a
              href="https://play.google.com/store/apps/details?id=com.bilu.lover"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={styles.p_img} src="./image/google.png" alt="" />
            </a> */}
            {/* iOS二维码 */}
          {/* </div> */}
        {/* </div> */}
      </div>
    </>
  );
}

export default Header;
