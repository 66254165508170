import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'
import Feature from '../pages/Feature';
import Home from '../pages/Home';
import About from '../pages/About';
import Header from './Header';
import Footer from './Footer';

function Layout() {
  return (
    <>
      <Router>
          <Header></Header>
        <div>
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route exact path="/Feature" component={Feature}></Route>
            <Route exact path="/About" component={About}></Route>
            <Redirect to="/" />
          </Switch>
        </div>
        <Footer></Footer>
      </Router>
    </>
  )
}

export default Layout