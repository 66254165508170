import styles from "../styles/Footer.module.scss";

function Footer() {
  return (
    <>
      <div className={styles.footer}>
        <div className={styles.detail}>
          <ul>
            <li>
              <a
                className={styles.comp}
                // href="https://applinks.hoya.tv/appindex/HoyaAppUserServiceAgreement.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                User Service Agreement
              </a>
              <span className={styles.block}></span>
            </li>
            <li>
              <a
                className={styles.comp}
                // href="https://applinks.hoya.tv/appindex/HoyaAppPrivacyPolicy.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </li>
          </ul>

          <ul style={{ marginTop: "8px" }}>
            <li>
              <a target="_blank" href="https://beian.miit.gov.cn">沪ICP备2021017780号-1</a>
            </li>
          </ul>
          <ul style={{ marginTop: "8px" }}>
            <li>
		 		      <a 
               target="_blank" 
               href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102007168" 
               className={styles.a}
              >
              <img src="./image/Jinghui.png" className={styles.img}/><p className={styles.p}
              >沪公网安备 31010102007168号</p></a>
		        </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Footer;
